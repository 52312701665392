body {
  color: #0000cd;
  font-family: myFirstFont;
}
.App {
  text-align: center;
}

.refresh {


}

.topButton {
  text-align: center;
  height: 30px;
  width: 30px;
  background: #bdbdbd;
  font-weight: bolder;
  font-size: 17px;
  border-style: outset;
}

.topButton:hover {
  border-style: inset;
}

@font-face {
  font-family: myFirstFont;
  src: url("Windows Regular.ttf");
}

@font-face {
  font-family: dos;
  src: url("dos.ttf");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.white {
  color: white;
}

.bg-custom {
  font-family: myFirstFont;
  background-image: linear-gradient(to right, #010a86, #05309d, #0d7fcd);
  text-align: center;
  border-style: ridge;
  color: white;
}



.App-header {
  background-color: #bdbdbd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


#game-board {


  display: flex;
  align-items: center;
  flex-direction: column;
}



#cont {
  background-image: url("square.png");
  background-position: center;
  background-repeat: no-repeat;

  background-size: 10px;

}

#refresh {
  color: white;
  background: red;
}



.letter {
  color: #3a3a3b;
  font-family: myFirstFont;
  border-style: inset;
  /*border: 2px solid gray;*/
  border-radius: 0px;
  margin: 2px;
  font-size: 2.5vh;
  font-weight: 500;
  /*height: 4rem;*/
  /*width: 4rem;*/
  /*height: 3vw;*/
  width: 6vh;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  /*font-family: myFirstFont;*/
  text-transform: uppercase;
}

.letter-normal {
  text-transform: uppercase;

}

.letter-yellow {
  color: white;
  background: goldenrod;
}

.letter-green {
  color: white;
  background: seagreen;
}

.letter-incorrect {
  color: white;
  background: #3a3a3b;
}

.row {
  display: flex;
}

#keyboard-cont {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#keyboard-cont div {
  display: flex;
}

.second-row {
  margin: 0.5rem 0;
}



.keyboard-button {
  color: white;
  border: 3px outset;
  font-family: myFirstFont;
  border-radius: 0px;
  margin: 2px;
  font-size: 2.5vh;
  font-weight: 1000;
  /*height: 20%;*/
  width: 20%;
  height: 6vh;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}

.keyboard-normal {
  border-style: outset;
  color: #3b3b3b;
  background: #bdbdbd;
  /*background: #474d5c;*/
}

.keyboard-normal:hover {
  border-style: inset;
}

.keyboard-green {
  border-style: outset;
  background: seagreen;
}

.keyboard-green:hover {
  border-style: inset;
  background: #184f33;
}

.keyboard-yellow {
  border-style: outset;
  background: goldenrod;
}

.keyboard-yellow:hover {
  border-style: inset;
  background: #9b7716;
}

.overlay-wrong {



  background-color:#0000cd;
  width:100%;
  height:100%;
  z-index:10;
  top:0;
  left:0;
  position: absolute;
  max-width: 100%;
  /*overflow-y: hidden;*/
  overflow-x: hidden;
  align-content: center;
}

.overlay-right {

  background-color: #117106;
  width:100%;
  height:100%;
  z-index:10;
  top:0;
  left:0;
  position: absolute;
  max-width: 100%;
  /*overflow-y: hidden;*/
  overflow-x: hidden;
  align-content: center;

}

.overlay-text {
  position: relative;
  font-family: dos;
  top: 150px;

  /*width: 100%;*/
  text-align: left;
  /*margin-top: -22px;*/
  margin-left: 10%;
  /*color: white;*/
  font-size: 100%;

}

.overlay-stats {
  position: relative;
  font-family: dos;
  top: 140%;
  /*bottom: 10%;*/
  /*width: 100%;*/
  text-align: left;
  /*margin-top: -22px;*/
  /*margin-left: 300px;*/
  /*color: white;*/
  font-size: 90%;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

.hide {
  display: none;
}

mark {
  color: #0000cd;
  background-color: #cccccc;
}

.success {

  color: #0c8200;
  background-color: #cccccc;

}

.overlay-image {
  font-family: dos;
  position: relative;
  top: 100px;
  /*text-align: center;*/
  /*margin-top: -22px;*/
  /*color: #0000cd;*/
  font-size: 100%

}

.overlay-word {
  font-family: dos;
  position: relative;
  top: 400px;
  font-size: 90%

}

.overlay-footer {
  font-family: dos;
  position: relative;
  top: 400px;
  cursor: pointer;
  font-size: 90%

}

.overlay-footer:hover {
  opacity: 75%;

}


.keyboard-incorrect {
  border-style: outset;
  background: #3a3a3b;
}

.keyboard-incorrect:hover {
  border-style: inset;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.shake {
  animation: shake 0.2s;
  animation-iteration-count: 2;
}

.hint {
  background: #cccccc;
  border: 3px outset;
  font-family: myFirstFont;
  border-radius: 0px;
  margin: 2px;
  font-size: 2.5vh;
  /*height: 20%;*/
  /*width: 20%;*/
  height: 6vh;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: lowercase;
}

.hint:hover {
  border-style: inset;
}

.letter-green:hover .content {
  transform: rotateY( 180deg ) ;
  transition: transform 0.5s;
}

@keyframes shake {
  0% { transform: translate(2px, 0px) rotate(0deg); }
  10% { transform: translate(-2px, 0px) rotate(0deg); }
  20% { transform: translate(-5px, 0px) rotate(0deg); }
  30% { transform: translate(-2px, 0px) rotate(0deg); }
  40% { transform: translate(2px, 0px) rotate(0deg); }
  50% { transform: translate(5px, 0px) rotate(0deg); }
  60% { transform: translate(2px, 0px) rotate(0deg); }
  70% { transform: translate(-2px, 0px) rotate(0deg); }
  80% { transform: translate(-5px, 0px) rotate(0deg); }
  90% { transform: translate(-2px, 0px) rotate(0deg); }
  100% { transform: translate(2px, 0px) rotate(0deg); }
}
